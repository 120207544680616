<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.banner") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.pages"
                v-model="formModel.status"
                :items="statuses.pages.banner"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text1"
                :key="selectedLocale.lang + '-text1'"
                :label="$t('FORM_INPUT_NAMES.text1')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text1']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.text1'] =
                    ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">


           <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text2"
                :key="selectedLocale.lang + '-text2'"
                :label="$t('FORM_INPUT_NAMES.text2')"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text2']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.text2'] = ''
                "
                :config="editorConfig"
              ></ckeditor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="
                  formModel.translations[selectedLocale.lang].button_text
                "
                :label="$t('FORM_INPUT_NAMES.button_text')"
                :id="dynamicID"
                :error-messages="
                  messages[
                    'translations.' + selectedLocale.lang + '.button_text'
                  ]
                "
                @keyup="
                  messages[
                    'translations.' + selectedLocale.lang + '.button_text'
                  ] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].url"
                :key="selectedLocale.lang + '-url'"
                :label="$t('FORM_INPUT_NAMES.url')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.url']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.url'] = ''
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .target
                "
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .target || 'self'
                "
                :items="targets"
                :label="$t('FORMS.target')"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="banner_background_type"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .banner_background_type || 'image'
                "
                :config="{
                  type: 'radio_group',
                  row: true,
                  items: [
                    {
                      label: 'BANNERS.BG_OF_BANNER_IS_IMAGE',
                      value: 'image',
                    },
                    {
                      label: 'BANNERS.BG_OF_BANNER_IS_EMBEDDED_VIDEO',
                      value: 'embedded_video_url',
                    },
                  ],
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields &&
                  (formModel.translations[selectedLocale.lang].custom_fields
                    .banner_background_type == 'image' ||
                    formModel.translations[selectedLocale.lang].custom_fields
                      .banner_background_type == '')
                "
                customFieldName="desktop_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .desktop_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [],
                  selectButtonText: 'FORM_INPUT_NAMES.desktop_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields &&
                  (formModel.translations[selectedLocale.lang].custom_fields
                    .banner_background_type == 'image' ||
                    formModel.translations[selectedLocale.lang].custom_fields
                      .banner_background_type == '')
                "
                customFieldName="mobile_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .mobile_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [],
                  selectButtonText: 'FORM_INPUT_NAMES.mobile_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>

            <v-col cols="12" md="12" sm="12" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields &&
                  formModel.translations[selectedLocale.lang].custom_fields
                    .banner_background_type == 'embedded_video_url'
                "
                customFieldName="embedded_video_url"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .embedded_video_url || ''
                "
                :config="{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.youtube_url',
                  value:
                    formModel.translations[selectedLocale.lang].custom_fields
                      .embedded_video_url || '',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions> -->

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Banners";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = ["url", "text1", "text2", "button_url"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "mobile_image",
  "desktop_image",
  "banner_background_type",
  "embedded_video_url",
  "target",
];

export const INITIAL_CUSTOM_FIELDS = {
  // name: "",
  // email: "",
  // favicon: "",
  // logo: "",
  // selectedLanguages: [],
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  name: "",
  position: null,
  type: null,
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
  attachments: [],
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },
      targets: [
        { key: "self", value: "Self" },
        { key: "blank", value: "Blank" },
      ],
    };
  },
  computed: {
    // ...mapGetters(["bannerTypeCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).text1
        : this.$t("MENU.NEW") + " " + this.$t("MENU.banner");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },

    computedLangItems() {
      return this.languages.map((item) => {
        return { label: item.name, value: item.lang };
      });
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        // this.selectedLocale = i18nService.languages.find((item) => {
        //   return item.lang == i18nService.getActiveLanguage();
        // });
        // this.selectedLocale = i18nService.userLanguages.find((item) => {
        //   return item.lang == i18nService.getUserActiveLanguage();
        // });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields) {
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              }
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
              this.$refs.form.resetValidation();
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();
          /*this.formModel.translations[this.selectedLocale.lang][
            "custom_fields"
          ]["radio"] = "alma";*/
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions(["fetchBannerType"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            let isTranslated = false;
            this.translatedAttributes.forEach((attribute) => {
              if (
                model.translations[langCode] &&
                model.translations[langCode][attribute] &&
                model.translations[langCode][attribute] != ""
              ) {
                isTranslated = true;
              }
            });
            if (isTranslated) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });

          if (Object.keys(translations).length == 0) {
            translations[this.selectedLocale.lang] =
              model.translations[this.selectedLocale.lang];
          }
          model.translations = Object.assign({}, translations);
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  mounted() {
    // this.fetchBannerType();
    this.setTranslatedAttributes();
  },
};
</script>

